import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';
import AlertDialog from "../../../shared/DialogBox/index";
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';
import * as config from "config/constants";
import {Button} from "@material-ui/core";

const MicroRegulatoryProcessesList = (props) => {
  const [microRegulatoryProcessNext, setMicroRegulatoryProcessNext] = useState('');
  const [microRegulatoryProcessData, setMicroRegulatoryProcessData] = useState();
  const [microRegulatoryProcessCount, setMicroRegulatoryProcessCount] = useState(0);
  const [microRegulatoryProcessDisplayCount, setMicroRegulatoryProcessDisplayCount] = useState(0);
  const [microRegulatoryProcessLoading, setMicroRegulatoryProcessLoading] = useState(false);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const numRecordPage = config.pageSize;

  const getMicroRegulatoryProcessList = async (regulationId) => {
    try {
      const { data } = await store.api.getMicroProcessByRegulation(regulationId);
      setMicroRegulatoryProcessData(data.results);
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessCount(data.count);
      data.count > numRecordPage
        ? setMicroRegulatoryProcessDisplayCount(numRecordPage)
        : setMicroRegulatoryProcessDisplayCount(data.count);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  const getNextMicroRegulatoryProcesses = async () => {
    try {
      setMicroRegulatoryProcessLoading(true);
      const { data } = await store.api.getNextDataWithURL(microRegulatoryProcessNext);
      let newMicroRegulatoryProcesses = microRegulatoryProcessData;
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        newMicroRegulatoryProcesses.push(process);
        if (arrayLength === i + 1) {
          setMicroRegulatoryProcessData(newMicroRegulatoryProcesses);
          setMicroRegulatoryProcessDisplayCount(microRegulatoryProcessDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setMicroRegulatoryProcessLoading(false);
      console.log(error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setMicroRegulatoryProcessLoading(false);
    }
  };

  const ValidationEvidenceByRegulation = async () => {
    try {
      setMicroRegulatoryProcessLoading(true);
      const params = window.location.href;
      let url = new URL(params);
      let regulationId = url.searchParams.get('regulation');
      let allResults = [];
      let count = 0;
      let fetchedCount = 0;
      let count10= 0;
      const total = microRegulatoryProcessDisplayCount / 10 ;
      while (count10 < total) {
        const {data} = await store.api.getValidationByRegulation(regulationId, count10);
        allResults = [...allResults, ...data.results];
        count += data.count;
        fetchedCount += data.results.length;
        count10 += 1
      }
      setMicroRegulatoryProcessData(allResults);
      setMicroRegulatoryProcessNext(null);
      setMicroRegulatoryProcessCount(fetchedCount);
      setMicroRegulatoryProcessDisplayCount(Math.min(count, numRecordPage));
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setMicroRegulatoryProcessLoading(false);
    }
  };

  useEffect(() => {
    const params = window.location.href;
    let url = new URL(params);
    let regulationId = url.searchParams.get('regulation');
    getMicroRegulatoryProcessList(regulationId);
  }, []);

  const navigateToMicroRegulatoryProcess = (microProcessId) => {
    const path = 'micro-regulatory-process-detail?micro_process='+microProcessId;
    history.push(path);
  };

  const getStatusClass = (microProcess) => {
    if (microProcess.validation_status === 'Passed') {
      return 'green-status';
    } else if (microProcess.evidence_status === 'Passed') {
      return 'evidence-status';
    } else {
      return 'red-status';
    }
};

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/regulation-documents-operational')}>
        Regulation Documents
      </div>
      <div className="header-icon"></div>
      <h4>
        Compliance Micro-Process Library
      </h4>
         <Button variant="contained" color="primary"  disabled={microRegulatoryProcessLoading} style={{
              position: 'absolute',
              top: 110,
              right: 40}} onClick={() =>ValidationEvidenceByRegulation()}>

          Validation Evidence Rules
        </Button>
      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {microRegulatoryProcessDisplayCount} of {microRegulatoryProcessCount} Micro
            Regulatory Processes
          </span>
        </li>
        {microRegulatoryProcessData !== undefined &&
          microRegulatoryProcessData.map((microProcess, i) => {
            const microProcessId = microProcess.id;

            return (
              <li key={i} onClick={() => navigateToMicroRegulatoryProcess(microProcessId)}>
                <span>{microProcessId}</span>
                <span className="title-spacing">{microProcess.micro_regulatory_process.name}</span>
                <div className="status-text">

                  <span className="owner"> Owner: {microProcess.owner.first_name} {microProcess.owner.last_name}  </span>
                  <span className="last-updated">Last Updated At {new Date(microProcess.last_updated).toISOString()}</span>
                </div>
                 <div className={`status ${getStatusClass(microProcess)}`}></div>
                <span
                  className={`applicable-status ${
                    microProcess.applicable === 'Applicable' ? '' : 'not-applicable'
                  }`}
                >
                  {microProcess.applicable}
                  <AlertDialog reason={microProcess.applicability_reason} name={microProcess.micro_regulatory_process.name} applicable={microProcess.applicable} />
                </span>


              </li>
            );
          })}
        {microRegulatoryProcessCount > numRecordPage && (
          <li className="get-next" key="next" onClick={() => getNextMicroRegulatoryProcesses()}>
            <span>{microRegulatoryProcessLoading ? 'Loading...' : 'Load '+ numRecordPage.toString() +' more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};

MicroRegulatoryProcessesList.defaultProps = {};

export default MicroRegulatoryProcessesList;